import './LoginStyle.css';
import React, {useState, useEffect} from 'react';
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '../../store/slices/UserSlice';
import { reqData } from '../../reqData';

const Login = () => {
    const [error, setError] = useState(false)
    const [phone, setPhone] = useState("")
    const [otp, setOtp] = useState("")
    const [showOtpInput, setShowOtpInput] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        navigate('/');
      }
    }, [navigate]);

    const handleLogin = async (e) => {
      e.preventDefault();
      const formattedPhoneNumber = `+91${phone}`;
      try {
        const response = await reqData('/auth/request-otp', 'POST', {
          mobile: formattedPhoneNumber,
        });
        setShowOtpInput(response.return);
      } catch (err) {
        setError(true);
      }
    };  
    
    const verifyOtp = async (e) => {
      e.preventDefault();
      try {
        const response = await reqData('auth/login-with-otp', 'POST', {
          mobile: `+91${phone}`,
          otp: otp,
        });
        console.log(response);

        if (response.user) {
          const user = response;
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(addUser(user));
          localStorage.setItem('generatedId', response.user.id);
          navigate('/personalDetails'); 
          console.log(response);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    };

  return (
    <div className='login'>
        {!showOtpInput ? (
          <form className='loginForm' onSubmit={handleLogin}>
            <div className="loginTitle">Login</div>
              <div className='phoneInputWrapper'>
                <div className='inputContainer'>
                  <span className='countryCode'>+91</span>
                  <div className='vertical-hr'></div>
                  <input 
                    type="number" 
                    name="userPhone" 
                    id="userPhone" 
                    onChange={(e) => setPhone(e.target.value)} 
                    placeholder='Your mobile number'
                  />
                </div>
              </div>
            {error ? <span className="error">Invalid Phone Number</span> : ""}
            <button type='submit' className='pageButton'>Login</button>
          </form>
        ) : ""}
        {showOtpInput ? (
          <form className='loginForm' onSubmit={verifyOtp}>
          <label className='loginTitle' htmlFor="otpInput">Enter OTP</label>
            <div className='inputContainer'>
              <input 
                type="number" 
                name="otp" 
                id="otpInput" 
                placeholder='Enter OTP' 
                value={otp} 
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            {error ? <span className="error">Invalid OTP</span> : ""}
          <button type='submit'>Verify OTP</button>
         </form>
        ) : ""}
    </div>
  )
}

export default Login
