import './Overview.css'
import React from 'react'
import QuesNumber from './QuesNumber'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Overview = ({question, setQuestion, results, quiz}) => {
  const navigate = useNavigate();  

  return (
    <div className='overviewMainContainer'>
      <div className="questions">
        <div className="quesHolder">
        {
          results.map((item) => {
            return(
              <QuesNumber key={item.question} ques={results.indexOf(item)} setQuestion={setQuestion} answer={item.answer}/>
            )
          })
        }
        </div>
      </div>
      <div className="endQuiz">
        <div className="endQuizBtn" onClick={() => navigate('/quizResult', {state: {results: results, quiz: quiz}})}>End Quiz</div>
      </div>
    </div>
  )
}

export default Overview