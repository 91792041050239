import React, {useState} from 'react';
import './CreateProfile.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const CreateProfile = () => {

  const initialProfile = {
    appearing_for: 'none', 
    class_in: 'none',
    universityName: '',
    college: '',
    board: 'none', 
    referralCode: '',
    district: '',
    collegeCode: '',
    language: 'none',
  };

  const [profile, setProfile] = useState(initialProfile);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = ({currentTarget: input}) => {
    setProfile({...profile, [input.name]: input.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if location.state.profileData exists before accessing it
    const profileData = location.state?.profileData || {};
  
    // Merge the existing profileData with the new profile data
    const updatedProfileData = { ...profileData, ...profile };
  
    navigate('/myProfile', { state: { profileData: updatedProfileData } });
  };

  return (
    <div className='mainContainer'>
        <h1>Your Academic Details will help us to understand you better</h1>
        <h3>Please help us with the necessary academic details</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="singleInput">
            <p>Your College / School Name<span>*</span></p>
            <input 
              type="text" 
              name="college" 
              id="college" 
              placeholder='Enter your college / school name' 
              onChange={handleChange}
              required
            />
          </div>
          <div className="singleInput">
            <p>What are you preparing for?<span>*</span></p>
            <select 
              name="appearing_for" 
              id="appearing_for" 
              onChange={handleChange}
              style={{ overflowY: 'auto', maxWidth: '100%' }}
              required
            >
              <option value="Select the exam">Select the exam</option>
              <option value="Neet 2023">Neet 2023</option>
              <option value="Neet 2024">Neet 2024</option>
              <option value="Neet 2025">Neet 2025</option>
              <option value="Neet 2026">Neet 2026</option>
            </select>
          </div>
          <div className='singleInput'>
          <p>Which class you are in?<span>*</span></p>
            <select 
              name="class_in" 
              id="class_in" 
              onChange={handleChange}
              style={{ overflowY: 'auto', maxWidth: '100%' }}
              required
            >
              <option value="Select the exam" disabled>I am in</option>
              <option value="12th Class">12th Class</option>
              <option value="11th Class">11th Class</option>
              <option value="10th Class">10th Class</option>
              <option value="Dropper">Dropper</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className='singleInput'>
            <p>Preferred langauge for exam<span>*</span></p>
            <select 
              name="language" 
              id="language" 
              onChange={handleChange}
              style={{ overflowY: 'auto', maxWidth: '100%' }}
              required
            >
              <option value="Select the exam" disabled>I'am appearing Neet in</option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className='singleInput'>
            <p>Select Board<span>*</span></p>
            <select 
              name="board" 
              id="board" 
              onChange={handleChange}
              style={{ overflowY: 'auto', maxWidth: '100%' }}
              required
            >
              <option value="Select Board" disabled>Select Board</option>
              <option value="CBSE">CBSE</option>
              <option value="ICSE">ICSE</option>
              <option value="State Board">State Board</option>
              <option value="International Board">International Board</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="singleInput">
            <p>Enter your referral code<span>*</span></p>
            <input 
              type="text" 
              name="referralCode" 
              id="referralCode" 
              placeholder='Enter your referral code' 
              onChange={handleChange}
              required
            />
          </div>
          <div className="singleInput">
            <p>Enter your college code<span>*</span></p>
            <input 
              type="text" 
              name="collegeCode" 
              id="collegeCode" 
              placeholder='Enter your college code' 
              onChange={handleChange}
              required
            />
          </div>
          <div className='center-container'>
            <Link to="/myProfile" state={{ profileData: profile }}>
              <button type='submit' className='submitBtn' onClick={(e) => handleSubmit(e)}>Next</button>
            </Link>
          </div>
        </form>
    </div>
  )
}

export default CreateProfile