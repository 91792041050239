import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {reqData} from '../../reqData'

const initialState = () => {
    const quiz = sessionStorage.getItem("quiz")
    if(quiz === null){
        return {
            quiz: null,
            currentQuiz: null
        }
    }else{
        return JSON.parse(quiz)
    }
}

export const getCurrentQuiz = createAsyncThunk("currentQuiz", async (data) => {    
    try {
        const response = await reqData(`/quiz/details/${data.quizId}`, 'GET', null, data.token)
        return response.data.questions
    } catch (error) {
        return {message: error.message};
    }
})

const quizSlice = createSlice({
    name: "quiz",
    initialState: initialState,
    reducers: {
        addQuiz(state, action){
            console.log(action.payload);
            state.quiz = action.payload
            state.currentQuiz = null
        },
        addQuestions(action, state){
            console.log(action.payload);
            state.currentQuiz = action.payload
        },
        removeQuiz(state, action){
            sessionStorage.removeItem("quiz")
            state.quiz = null
            state.currentQuiz = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentQuiz.fulfilled, (state, action) => {
            console.log(`PAYLOAD: ${action.payload}`);
            state.currentQuiz = action.payload
        })
    }
})

export default quizSlice.reducer 

export const { addQuiz, removeQuiz, addQuestions } = quizSlice.actions;