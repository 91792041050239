import React from 'react'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import './Review.css'
import { useSelector } from 'react-redux'

const Review = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const results = location.state.results
    const quiz = location.state.quiz
    const quesNo = location.state.quesNo
    const allQues = useSelector((state) => {
        return state.quiz.currentQuiz
    })

    const getOption = (option) => {
        switch(option){
            case 'option_1': return 'A'; 
            case 'option_2': return 'B'; 
            case 'option_3': return 'C'; 
            case 'option_4': return 'D'; 
            default: return 'Not Answered'; 
        }
    }

    const correct = getOption(allQues[quesNo].correctAnswer[0])
    const yourAnswer = getOption(results[quesNo].answer)

  return (
    <div className='reviewMainContainer'>
        <div className="topBar"><span className="backArrow" onClick={() => navigate('/quizResult', {state: {results: results, quiz: quiz}})}><AiOutlineArrowLeft/><span className='back'>Back</span></span></div>     
        <div className="resultInnerContainer">
            <div className="reviewDetails">
                <div className="reviewTitle">
                    <span>{`Question ${quesNo+1}`}</span>
                </div>
                <div className="review">
                    <div className="reviewQuestion" style={{backgroundImage: `url(${allQues[quesNo].textImg})`, backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                        
                    </div>
                    <div className="optionContainer">
                        <div className="correctOption">
                            {`Correct Answer: ${correct}`}
                        </div>
                        <div className="yourOption"  style={correct === yourAnswer ? {backgroundColor: 'green'} : {backgroundColor: 'red'}}>
                            {`Your Answer: ${yourAnswer}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Review