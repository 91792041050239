import React, { useEffect, useState } from 'react'
import './Status.css'
import { BsCheck2 } from 'react-icons/bs'
import { RxCross2 } from 'react-icons/rx'
import { GoDotFill } from 'react-icons/go'

const Correct = (props) => {

  const [style, setStyle] = useState({})

  const getStyle = () => {
    if(props.status === 'correct'){
      setStyle( {
        color: 'darkgreen',
        backgroundColor: 'rgb(0,255,0,1)'
      })
    } else if( props.status === 'incorrect'){
      setStyle({
        color: 'brown',
        backgroundColor: 'rgb(255,0,0,1)'
      })
    } else{
      setStyle({
        color: 'black',
        backgroundColor: 'lightgray'
      })
    }
  }

  useEffect(() => {
    getStyle()
  }, [props.status])

  return (
    <div className='statusContainer' style={style}>
        <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
            props.status === 'correct' ? <BsCheck2/> : props.status === 'incorrect' ? <RxCross2/> : <GoDotFill/>
          }
        </span>
        <span style={{padding: '0 0.25em'}}>
          {props.status}
        </span>
    </div>
  )
}

export default Correct