import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import './App.css';
import CreateProfile from './pages/createprofile/CreateProfile';
import CreateProfileTwo from './pages/createprofile/CreateProfileTwo';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import QuizResult from './pages/quiz-results/QuizResult';
import QuizPage from './pages/quiz/QuizPage';
import Review from './pages/review/Review';
import SpectatorRoom from './pages/spectator-room/SpectatorRoom';
import CreateProfileFinal from './pages/createprofile/CreateProfileFinal';
function App() {

  const currentUser = useSelector((state) => state.user.user)

  const RequireAuth = ({ children }) => {
    return currentUser ? (children) : <Navigate to="/login" />
  }
console.log(currentUser);
  const router = createBrowserRouter([
  {
    path: "/login",
    element: <><Login/></>,
  },
  {
    path: "/",
    element: <RequireAuth><Home/></RequireAuth>,
  },
  {
    path: "/personalDetails",
    element: <RequireAuth><CreateProfile/></RequireAuth>,
  },
  {
    path: "/spectatorRoom",
    element: <RequireAuth><SpectatorRoom/></RequireAuth>,
  },
  {
    path: "/quizPage",
    element: <RequireAuth><QuizPage/></RequireAuth>,
  },
  {
    path: "/quizResult",
    element: <RequireAuth><QuizResult/></RequireAuth>,
  },
  {
    path: "/review",
    element: <RequireAuth><Review/></RequireAuth>,
  },
  {
    path: "/academicDetails",
    element: <CreateProfileTwo/>,
  },
  {
    path: "/users/:generatedId",
    element: <CreateProfileFinal/>,
  },
 

  ])

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
