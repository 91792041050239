import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
    const user = localStorage.getItem("user")
    if(user === null){
        return {
            user: null,
            isLoggedIn: false
        }
    }else{
        return JSON.parse(user)
    }
}

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        addUser(state, action){
            console.log(action.payload);
            state.user = action.payload
            state.isLoggedIn = true
        },
        removeUser(state, action){
            localStorage.removeItem("user")
            state.user = null
            state.isLoggedIn = false
        }
    },
})

export default userSlice.reducer 

export const { addUser, removeUser } = userSlice.actions;