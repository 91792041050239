import React from 'react';
import { useDispatch } from 'react-redux';
import { removeUser } from '../../store/slices/UserSlice';
import { removeQuiz } from '../../store/slices/QuizSlice';
import { useNavigate } from 'react-router-dom';

const LeftPanelCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(removeUser());
    dispatch(removeQuiz());
    navigate('/login');
  };

  const handleClick = (e, id) => {
    switch (id) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/personalDetails')
        break;
      case 2:
        handleLogout(e);
        break;
      default:
    }
  };

  return (
    <div
      className='leftPanelCardContainer'
      onClick={(e) => handleClick(e, props.content.id)}
      style={props.style} 
    >
      <div className="leftPanelContent">{props.content.data}</div>
    </div>
  );
};

export default LeftPanelCard;
