import './QuesResult.css'
import Correct from '../Status/Correct'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const QuesResult = (props) => {
  const navigate = useNavigate()
  return (
    <div className='quesResultMainContainer'>
        <div className="questionNumber"><span className='q'>{`Question ${props.question.question+1}`}</span>
        {/* <span className='timeTakenResult'>Time Taken: 1 minute</span> */}
        </div>
        <Correct status={props.question.status}/>
        <div className="reviewQues" onClick={() => navigate('/review', {state: {quesNo: props.question.question, results: props.results, quiz: props.quiz}})}>Review</div>
    </div>
  )
}

export default QuesResult