import React, { useEffect, useRef, useState } from 'react'
import './QuizPage.css'
import Question from '../../components/Quiz/Question/Question'
import Overview from '../../components/Quiz/Overview/Overview'
import { useNavigate } from 'react-router-dom'
import Popup from '../../components/Popup/Popup'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import moment from 'moment/moment'

const QuizPage = () => {

  const [question, setQuestion] = useState(0)
  const [results, setResults] = useState([])
  const [load, setLoad] = useState(false)
  const location = useLocation()
  const quiz = location.state.quiz
  const allQuestions = useSelector((state) => {
    return state.quiz.currentQuiz
  })
  const [seconds, setSeconds] = useState(allQuestions.length*60);
  const ct = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    for(let i = 0; i < allQuestions.length; i++){
      const temp = results
      temp.push({question: i, answer: ''})
      setResults(temp)
    }
    setLoad(true)
  }, [])

  const [popup, setPopup] = useState(false)

  const decreaseNum = () => setSeconds((prev) => prev - 1);

  useEffect(() => {
    ct.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(ct.current);
  }, [])

  console.log(ct.current);
  
  let minutes = Math.floor(seconds / 60);
  let extraSeconds = seconds % 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;

  if(seconds === 0){
    navigate('/quizResult', {state: {quiz: quiz, results: results}})
  }

  return (
    <div className='quizMainContainer'>
        <div className="topBar"><span className="backArrow" onClick={() => setPopup(true)}><AiOutlineArrowLeft/><span className='back'>Back</span></span><span className="timeRemaining">{`Time Left: ${minutes}:${extraSeconds}`}</span></div>     
          { popup ? <Popup setPopup={setPopup}/> : "" }
          <div className="quizPageInnerContainer">
            <div className="quesContainer">
                <Question question={question} setQuestion={setQuestion} results={results} setResults={setResults}/>
            </div>
            <div className="overviewContainer">
                {
                  load ?
                  <Overview question={question} setQuestion={setQuestion} results={results} quiz={quiz}/>
                  : ''
                }
            </div> 
        </div>  
    </div>
  )
}

export default QuizPage