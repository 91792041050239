import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './CreateProfileFinal.css';
import '../quiz-results/QuizResult.css'

function CreateProfileFinal() {
  const location = useLocation();
  const initialProfileData = location.state?.profileData || {
    name: '',
    email: '',
    state: '',
    gender: '',
    college: '',
    appearing_for: '',
    class_in: '',
    language: '',
    board: '',
    referralCode: '',
    collegeCode: '',
  };
  const [profileData, setProfileData] = useState(initialProfileData);
  const [isEditing, setIsEditing] = useState(false);

  console.log('Initial Profile Data: ', profileData);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const saveChanges = () => {
    console.log('Updated Profile Data:', profileData);
    setIsEditing(false);
    localStorage.setItem('hasProfile', 'true');
  };

  useEffect(() => {
    const storedProfileData = JSON.parse(localStorage.getItem('user')).user;
    if (storedProfileData) {
      console.log("user data mil gaya",storedProfileData)
      setProfileData(storedProfileData);
    }
  }, []);

  return (
    <>
      {isEditing ? (
        <>
        <div className="topBar">
            <span className='back'>Edit Profile</span>
        </div>
          <div className='mainContainer'>
            <div className='singleInput'>
              <p>Your Full Name</p>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={profileData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className='singleInput'>
              <p>Your Email Id</p>
              <input
                type="text"
                name="email"
                placeholder="Email Id"
                value={profileData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className='singleInput'>
              <p>Your State</p>
              <input
                type="text"
                name="email"
                placeholder="State"
                value={profileData.state}
                onChange={handleInputChange}
              />
            </div>
            <div className='singleInput'>
              <p>Your Gender</p>
              <select 
                name="gender" 
                id="gender" 
                value={profileData.gender}
                onChange={handleInputChange}
                placeholder='Gender'
                style={{ overflowY: 'auto', maxWidth: '100%' }}
                required
              >
                <option value="Select gender">Select your Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="NOT SPECIFIED">Not Specified</option>
                <option value="FEMALE">Other</option>
              </select>
            </div>
            <div className='singleInput'>
              <p>Your College / School Name</p>
              <input 
                type="text" 
                name="college" 
                id="college" 
                placeholder='Your college / school name' 
                value={profileData.college}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="singleInput">
              <p>You preparing for</p>
              <select 
                name="appearing_for" 
                id="appearing_for" 
                value={profileData.appearing_for}
                onChange={handleInputChange}
                placeholder='Your are appearing for'
                style={{ overflowY: 'auto', maxWidth: '100%' }}
                required
              >
                <option value="Select the exam">Select the exam</option>
                <option value="Neet 2023">Neet 2023</option>
                <option value="Neet 2024">Neet 2024</option>
                <option value="Neet 2025">Neet 2025</option>
                <option value="Neet 2026">Neet 2026</option>
              </select>
            </div>
            <div className='singleInput'>
              <p>Your class</p>
              <select 
                name="class_in" 
                id="class_in" 
                value={profileData.class_in}
                onChange={handleInputChange}
                placeholder='Your Class'
                style={{ overflowY: 'auto', maxWidth: '100%' }}
                required
              >
                <option value="Select the exam" disabled>I am in</option>
                <option value="12th Class">12th Class</option>
                <option value="11th Class">11th Class</option>
                <option value="10th Class">10th Class</option>
                <option value="Dropper">Dropper</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className='singleInput'>
              <p>Your preferred language</p>
              <select 
                name="language" 
                id="language" 
                value={profileData.language}
                onChange={handleInputChange}
                placeholder='Your preferred language'
                style={{ overflowY: 'auto', maxWidth: '100%' }}
                required
              >
                <option value="Select the exam" disabled>I'am appearing Neet in</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className='singleInput'>
              <p>Your Board</p>
              <select 
                name="board" 
                id="board" 
                value={profileData.board}
                onChange={handleInputChange}
                placeholder='Your Board'
                style={{ overflowY: 'auto', maxWidth: '100%' }}
                required
              >
                <option value="Select Board" disabled>Select Board</option>
                <option value="CBSE">CBSE</option>
                <option value="ICSE">ICSE</option>
                <option value="State Board">State Board</option>
                <option value="International Board">International Board</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="singleInput">
              <p>Your referral code</p>
              <input 
                type="text" 
                name="referralCode" 
                id="referralCode" 
                placeholder='Your referral code' 
                value={profileData.referralCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="singleInput">
              <p>Enter your college code</p>
              <input 
                type="text" 
                name="collegeCode" 
                id="collegeCode" 
                placeholder='Your college code' 
                value={profileData.collegeCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <>
              <Link to="/">
                <button className="saveButton" onClick={saveChanges} style={{color: 'white'}}>Save</button>
              </Link>
            </>
          </div>
        </>
      ) : (
        <>
          <div className="mainContainer">
            <div className="singleInput">
              <p>Name: {profileData.name}</p>
            </div>
            <div className="singleInput">
              <p>Email: {profileData.email}</p>
            </div>
            <div className="singleInput">
              <p>State: {profileData.state}</p>
            </div>
            <div className="singleInput">
              <p>Gender: {profileData.gender}</p>
            </div>
            <div className="singleInput">
              <p>College/School Name: {profileData.college}</p>
            </div>
            <div className="singleInput">
              <p>Appearing For: {profileData.appearing_for}</p>
            </div>
            <div className="singleInput">
              <p>Class In: {profileData.class_in}</p>
            </div>
            <div className="singleInput">
              <p>Language: {profileData.language}</p>
            </div>
            <div className="singleInput">
              <p>Board: {profileData.board}</p>
            </div>
            <div className="singleInput">
              <p>Referral Code: {profileData.referralCode}</p>
            </div>
            <div className="singleInput">
              <p>College Code: {profileData.collegeCode}</p>
            </div>
            <button onClick={handleEditClick} className='saveButton' style={{color: 'white'}}>Edit</button>
          </div>
        </>
      )}
    </>
  );
}

export default CreateProfileFinal;
