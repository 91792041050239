import { useNavigate } from 'react-router-dom'
import './SpectatorRoom.css'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentQuiz} from '../../store/slices/QuizSlice'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { BsRecordFill } from 'react-icons/bs'

const SpectatorRoom = () => {
  
  const token = useSelector((state) => {
    return state.user.user.tokens.access.token
  })

  const [ct, setCt] = useState('')
  const dispatch = useDispatch()
  const location = useLocation()
  const quiz = location.state.quiz

  
  function getTime(inputDate){
    const hour = inputDate.slice(11,13)
    const minute = inputDate.slice(14,16)
    const second = inputDate.slice(17,19)

    const ampm = hour >= 12 ? 'PM' : 'AM'
    const hrs = hour % 12
    const hours = hrs ? hrs : 12

    return `${hours}:${minute}:${second} ${ampm}`
}

  const startTime = getTime(quiz.startDate)
  const endTime = getTime(quiz.endDate)
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDay()

  setInterval(() => {
    const currentTime = new Date().toLocaleTimeString()
    const milliseconds = Math.floor(new Date(`${year}/${month}/${day} ${endTime}`) - new Date(`${year}/${month}/${day} ${currentTime}`))
    const seconds = Math.floor(milliseconds/1000)
    const minutes = Math.floor(seconds/60)
    const hours = Math.floor(minutes/60)
    setCt(`${hours} hours ${minutes%60} minutes ${seconds%60} seconds`);
  }, 1000)

  useEffect(() => {
    function fetchCurrentQuiz(token){
      dispatch(getCurrentQuiz({quizId: quiz.id, token: token}))
    }
    fetchCurrentQuiz(token)
  }, [])

  const navigate = useNavigate()
  return (
    <div className='mainSpecContainer'>
        <div className="topBar"><div className="backArrow" onClick={() => navigate(-1)}><AiOutlineArrowLeft/><span className='back'>Back</span></div></div>
        <div className="specInnerContainer">
          <div className="quizTime">{`Live quiz competition at`}</div>
          <div className="quizTime">{startTime}</div>
          <div className="quizDetails">
            <div className="quizName">{`${quiz.name}`}</div>
            <div className="quizQuesCount">{`${quiz.questions.length} Questions`}</div>
            <div className="quizMarks">{`${quiz.quizMarks} Marks`}</div>
            <div className="marksDetails">
              <h4 className="correctAnswer">+4 Correct Answer</h4>
              <h4 className="incorrectAnswer">-1 Incorrect Answer</h4>
            </div>
            <div className="quizMarks" style={{color: 'darkred', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}><BsRecordFill style={{padding: '0 0.25em'}}/>{`${quiz.botCount} Students Live`}</div>
            <button className="beginQuiz" onClick={() => {navigate('/quizPage', {state: {quiz: quiz}})}} disabled={ct!==''?false:true}>
              <div className="quizEnd">Quiz will end in</div>
              <div className="quizEndTime">{ct}</div>
              <div className="quizEnd">{`(Click here)`}</div>
            </button>
          </div>
        </div>
    </div>
  )
}

export default SpectatorRoom