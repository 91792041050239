import './Home.css'
import LeftPanelCard from '../../components/PanelCard/LeftPanelCard'
import QuizCard from '../../components/Quiz/Card/QuizCard'
import { useEffect, useState } from 'react'
import { reqData } from '../../reqData'
import { useDispatch, useSelector } from 'react-redux'
import {addQuiz} from '../../store/slices/QuizSlice'
import Logo from '../../assets/images/logo.png'

const Home = () => {

  const [isLoaded, setIsLoaded] = useState(false);

  const token = useSelector((state) => {
    return state.user.user.tokens.access.token
  })

  const quizData = sessionStorage.getItem('quiz')

  const dispatch = useDispatch()

  const quizzes = useSelector((state) => {
    return state.quiz.quiz
  })

  useEffect (() => {
    let dateObj = new Date();
 
    let month = String(dateObj.getMonth() + 1)
        .padStart(2, '0');
        
    let day = String(dateObj.getDate())
        .padStart(2, '0');
    
    let year = dateObj.getFullYear();
    let output = year + '-' + month + '-' + day;

    async function fetchQuiz(token){
      const url = `/quiz/startDate/${output}`
      const response = await reqData(url, 'GET', null, token)
      console.log(response);
      sessionStorage.setItem("quiz",JSON.stringify(response.data))
      dispatch(addQuiz(response.data))
      setIsLoaded(true)
    }

    if(quizData === null){
      fetchQuiz(token)
    }else{
      dispatch(addQuiz(JSON.parse(quizData)))
      setIsLoaded(true)
    }
}, []);

  const leftPanelCardContent = [
    {
      id: 0,
      data: 'Quizzes',
      style: {
        fontSize: '1.25em',
        fontWeight: 'normal',
        marginTop: '1em',
        backgroundColor: '#8C3562',
        color: 'white',
        marginBottom: '2px',
        padding: '0.5em 1em', 
        borderRadius: '8px',
        cursor: 'pointer',
      }
    },
    {
      id: 1,
      data: 'Profile',
      style: {
        fontSize: '1.25em',
        fontWeight: 'normal',
        marginTop: '1em',
        backgroundColor: '#8C3562',
        color: 'white',
        marginBottom: '2px',
        padding: '0.5em 1em', 
        borderRadius: '8px',
        cursor: 'pointer',
      }
    },
    {
      id: 2,
      data: 'Logout',
      style: {
        fontSize: '1.25em',
        fontWeight: 'normal',
        marginTop: '1em',
        backgroundColor: '#8C3562',
        color: 'white',
        marginBottom: '2px',
        padding: '0.5em 1em', 
        borderRadius: '8px',
        cursor: 'pointer',
      }
    }
  ]

  return (
    <div className="mainHomeContainer">
    <div className="leftPanel">
      <div className='logo' style={{backgroundImage: `url(${Logo})`}}></div>
      <div className="leftPanelCards">
        { leftPanelCardContent.map((content)=>{
          return (
              <LeftPanelCard 
                key={content.id} 
                content={content}
                style={content.style}
                className="buttonStyles"
              />
          )
        })}
      </div>
    </div>
    <div className="mainPanel">
        <div className="mainPanelInnerContainer">
        {isLoaded && quizzes?.map((quiz) => {
          return (
            <QuizCard key={quiz.id} quiz={quiz} />
          );
        })}
        </div>
    </div>
  </div>
  )
}

export default Home