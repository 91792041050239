import { useNavigate } from 'react-router-dom'
import './QuizCard.css'

import React from 'react'

const QuizCard = (props) => {

  const navigate = useNavigate()
  
  const myStyle1 = {
    display: 'flex',
    width: "65%",
    justifySelf: "center",
    justifyContent: "center",
    alignItems: "center",
    height: '40vh',
    margin: '1em 2em',
    borderRadius: '1.5em',
    cursor: 'pointer',
    backgroundColor: 'rgb(140, 55, 98)',
    color: 'white',
    backgroundImage: `url(${props.quiz.img})`,
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.5)'
  }
  
  const myStyle2 = {
    display: 'flex',
    width: "85%",
    justifyContent: "center",
    alignItems: "center",
    height: 'calc(20vw)',
    margin: '1em 2em',
    borderRadius: '1.5em',
    cursor: 'pointer',
    backgroundColor: 'rgb(140, 55, 98)',
    color: 'white',
    backgroundImage: `url(${props.quiz.img})`,
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.5)'
  }

  console.log(props.quiz.url)

  return (
    <div className='quizCardMainContainer' style={window.innerWidth <= 1280 ? (window.innerWidth <= 768 ? myStyle1 : myStyle2) : myStyle2 } onClick={()=>navigate('/spectatorRoom', {state: {quiz: props.quiz}})}>
      
    </div>
  )
}

export default QuizCard