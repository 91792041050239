import './QuesNumber.css'

import React from 'react'

const QuesNumber = ({ques, setQuestion, answer}) => {
  return (
    <div className='quesNumContainer' onClick={() => setQuestion(ques)} style={ answer !== '' ? {backgroundColor: 'lightgreen'} : {backgroundColor: "#f1f1f1"}}>
      {ques+1}
    </div>
  )
}

export default QuesNumber