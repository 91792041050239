import React, {useEffect, useState} from 'react';
import './CreateProfile.css';
import { Link, useNavigate } from 'react-router-dom';

const CreateProfile = () => {

  const initialProfile = {
    name: '',
    email: '',
    state: '',
    gender: 'none',
  };

  const [profile, setProfile] = useState(initialProfile);
  const navigate = useNavigate();

  useEffect(() => {
    const storedGeneratedId = localStorage.getItem('generatedId');
    console.log("Stored generatedId:", storedGeneratedId);
    if (storedGeneratedId) {
      fetch(`/users/${storedGeneratedId}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('failed to fetch user profile');
          }
        })
        .then((userData) => {
          setProfile(userData);
        })
        
        .catch((error) => {
          console.error(error);
        });
        navigate(`/users/${storedGeneratedId}`);
    } else {
      console.log("user not found");
    }
  }, [navigate]);

  const handleChange = ({currentTarget: input}) => {
    setProfile({...profile, [input.name]: input.value})
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const generatedId = localStorage.getItem('generatedId'); 
    localStorage.setItem('userProfile', JSON.stringify(profile));
    navigate('/academicDetails');
  };
  

  return (
    <div className='mainContainer'>
        <h1>Let us personalize the learning experience for you</h1>
        <h3>Please help us with the necessary personal details</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="singleInput">
            <p>Your Full Name <span>*</span></p>
            <input 
              type="text" 
              name="name" 
              id="fullName" 
              placeholder='Enter your full name' 
              onChange={handleChange}
              required
            />
          </div>
          <div className="singleInput">
            <p>Email Id <span>*</span></p>
            <input 
              type="text" 
              name="email" 
              id="email" 
              placeholder='Enter your email address' 
              onChange={handleChange}
              required
            />
          </div>
          <div className='singleInput'>
            <p>State <span>*</span></p>
            <input 
              type="text" 
              name="state"
              id="state"
              placeholder='Enter your state'
              onChange={handleChange}
              required
              />
          </div>
          <div className='singleInput'>
            <p>Gender <span>*</span></p>
            <select 
              name="gender" 
              id="gender" 
              onChange={handleChange}
              style={{ overflowY: 'auto', maxWidth: '100%' }}
              required
            >
              <option value="Select gender">Select your Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="NOT SPECIFIED">Not Specified</option>
              <option value="FEMALE">Other</option>
            </select>
          </div>
          <div className='center-container'>
            <Link to="/academicDetails">
              <button type='submit' className='submitBtn' onClick={(e) => handleSubmit(e)}>Next</button>
            </Link>
          </div>
        </form>
    </div>
  )
}

export default CreateProfile