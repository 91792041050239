import React from 'react'
import './Popup.css'
import { useNavigate } from 'react-router-dom'

const Popup = (props) => {
    
    const navigate = useNavigate()

    const handlePopupBtn = () => {
        props.setPopup(false)
    }

    const navToHome = (e) => {
        e.preventDefault()
        props.setPopup(false)
        navigate('/')
    }

    return (
    <div>
        <div className='popup-overlay'>
            <div className="popup-overlay-container">
                <div className="popup-overlay-item">
                    <div className="popupMessage">Are you sure you want to quit the quiz?</div>
                    <div className="popupButton">
                        <button className='popup-btn-cancel' onClick={handlePopupBtn}>Cancel</button>
                        <button className='popup-btn' onClick={(e) => navToHome(e)}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Popup