import axios from './axios'
export const reqData = async (url, method, data, token) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    };

    if (token != null){
        headersList = {...headersList, 'Authorization': `Bearer ${token}`}
    }

    let bodyContent = JSON.stringify(data);

    console.log(headersList);

    let reqOptions = {
        url: url,
        method: method,
        headers: headersList,
        data: bodyContent,
    };

    try {
        let response = await axios.request(reqOptions);
        if(response.status === 200){
            return response.data
        }
    } catch (err) {
        return {isSuccess: false, message: err.response.data.message}
    }
}