import { useSelector } from 'react-redux'
import './Question.css'

import React, { useEffect, useState } from 'react'

const Question = ({question, setQuestion, results, setResults}) => {

  const [selected, setSelected] = useState('')

  const allQuestions = useSelector((state) => {
    return state.quiz.currentQuiz
  })

  const handleSelect = (e, option) => {
    // e.preventDefault()
    setSelected(option)
  }

  function saveOption(option){
    const temp = results
    temp[question].answer = option
    setResults(temp)
  }

  useEffect(() => {
    const existing = results[question]
    if(existing !== undefined){
      if(existing.answer === ''){
        setSelected('')
      }else{
        setSelected(existing.answer)
      }
    }
  }, [question])

  return (
    <div className='questionMainContainer'>
        <div className="quesNumber"><span>{`Question ${question+1}`}</span></div>
        <div className="question">
            <div className="ques" style={{backgroundImage: `url(${allQuestions[question].textImg})`, backgroundRepeat: "no-repeat", backgroundSize: "contain"}}></div>
            <div className="choice">
                <div className="singleChoice" style={{
                  backgroundColor: selected === 'option_1' ? 'rgb(140,55,98)' : '',
                  color: selected === 'option_1' ? 'white' : ''
                }} onClick={(e) => handleSelect(e, 'option_1')}>A</div>
                <div className="singleChoice" style={{
                  backgroundColor: selected === 'option_2' ? 'rgb(140,55,98)' : '',
                  color: selected === 'option_2' ? 'white' : ''
                }} onClick={(e) => handleSelect(e, 'option_2')}>B</div>
                <div className="singleChoice" style={{
                  backgroundColor: selected === 'option_3' ? 'rgb(140,55,98)' : '',
                  color: selected === 'option_3' ? 'white' : ''
                }} onClick={(e) => handleSelect(e, 'option_3')}>C</div>
                <div className="singleChoice" style={{
                  backgroundColor: selected === 'option_4' ? 'rgb(140,55,98)' : '',
                  color: selected === 'option_4' ? 'white' : ''
                }} onClick={(e) => handleSelect(e, 'option_4')}>D</div>
            </div>
        </div>
        <div className="userResponse">
          <div className="clearResponse" onClick={() => {
            const temp = results
            temp[question].answer = ''
            setResults(temp)
            setSelected('')
          }}>Clear Response</div>
          <button className="prevQuestion" onClick={() => {
            saveOption(selected)
            if(question > 0){
              setQuestion(question - 1)
              setSelected('')
            }
          }}>Previous</button>
          <button className="saveChoiceBtn" onClick={() => {
            console.log(selected);
            
          }}>Save</button>
          <button className="nextQuestion" onClick={() => {
            saveOption(selected)
            if(question < allQuestions.length-1){
              setQuestion(question + 1)
              setSelected('')
            }
          }}>Next</button>
        </div>
    </div>
  )
}

export default Question