import { useNavigate } from 'react-router-dom'
import './QuizResult.css'
import { Doughnut } from 'react-chartjs-2'
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';
import React, { useEffect } from 'react'
import QuesResult from '../../components/Quiz/Result/QuesResult';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { GiPlainCircle } from 'react-icons/gi'

const QuizResult = () => {
  const allQuestions = useSelector((state) => {
    return state.quiz.currentQuiz
  })

  const location = useLocation()
  const results = location.state.results
  const quiz = location.state.quiz
  const status = results

  const calculate = () => {
    let correct = 0
    let incorrect = 0
    let na = 0
    for(let i = 0;i < allQuestions.length; i++){
      const validAnswer = allQuestions[i].correctAnswer
      if(validAnswer.includes(results[i].answer)){
        correct = correct + 1
        status[i] = {...status[i], status: 'correct'}
      }else if(results[i].answer === ''){
        na = na + 1
        status[i] = {...status[i], status: 'not answered'}
      }else{
        incorrect = incorrect + 1
        status[i] = {...status[i], status: 'incorrect'}
      }
    }

    return {
      correct,
      incorrect,
      na,
      data: [
        { name: 'Correct', ques: correct },
        { name: 'Not Answered', ques: na },
        { name: 'Incorrect', ques: incorrect },
      ],
    };
  }

  const { correct, incorrect, na, data } = calculate()
  const marks = (data[0].ques * 4) - data[2].ques

  function calcPercentage() {
    const percent = ((marks / (allQuestions.length * 4)) * 100).toFixed(2)
    return `${percent} %`
  }

  const COLORS = ['#3DCC91', '#FFB366', '#FF7373'];

  const navigate = useNavigate()

  return (
    <div className='resultMainContainer'>
      <div className="topBar">
        <span className="backArrow" onClick={() => navigate('/')}><AiOutlineArrowLeft /><span className='back'>Back</span></span>
      </div>
      <div className="resultInnerContainer">
        <div className="resultDetails">
          <div className="resultTitle">
            <span>Your Performance</span>
          </div>
          <div className="results">
            <div className="resultStats">
              <div className="resultTextual">
                <div className="yourScore">{`Your Score: ${marks}`}</div>
                <div className="quizTitle">{`Quiz Name: ${quiz.name}`}</div>
              </div>
              <div className="resultGraph">
                <div className="graphSvg">
                  <ResponsiveContainer width={"100%"} height={300}>
                    <PieChart>
                      <Pie data={data} dataKey="ques" outerRadius={window.innerWidth <= 768 ? 80 : 110}
                        innerRadius={window.innerWidth <= 768 ? 60 : 80}>
                        {
                          data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                        }
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <ul className="legend">
                    <li className="answerStatus"><GiPlainCircle />Correct: {correct}</li>
                    <li className="answerStatus"><GiPlainCircle />Incorrect: {incorrect}</li>
                    <li className="answerStatus"><GiPlainCircle />Not Answered: {na}</li>
                  </ul>
                </div>
                <div className="percentage"><span>{calcPercentage()}</span></div>
              </div>
            </div>
            <div className="resultBreakdown">
              {
                status.map((singleQuestion) => {
                  return (
                    <QuesResult question={singleQuestion} results={results} quiz={quiz} />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizResult
